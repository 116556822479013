<template>
  <div class="products">
    <div class="page-header">
      <h1>{{ $t('Brands / Restaurants') }} > {{ $t('Restaurants') }}</h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Restaurants') }}</h2>
        <router-link :to="{ name: 'AddRestaurant' }">
          <el-button class="action-button" type="primary">{{ $t('Add Restaurant') }}</el-button>
        </router-link>
      </div>
      <div class="product-list">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">{{ $t('Related Brand') }}</th>
              <th scope="col">{{ $t('Restaurant Logo') }}</th>
              <th scope="col">{{ $t('Restaurant Name') }}</th>
              <th scope="col">{{ $t('Actions') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="restaurant in restaurants">
              <td>{{ restaurant.related_brand }}</td>
              <td class="product-image-td">
                <div class="product-image-wrapper">
                  <img class="product-image img-fluid" v-if="restaurant.logo" :src="restaurant.logo" />
                  <img class="product-image img-fluid" v-else src="@/assets/no-image.jpg" />
                </div>
              </td>
              <td>{{ restaurant.name }}</td>
              <td>
                <div class="action-links">
                  <router-link class="action-link" :to="{name:'EditRestaurant', params: {id: restaurant.id}}">
                    {{ $t('Edit') }}
                  </router-link>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Brand from '@/lib/brand';
import Restaurant from '@/lib/restaurant';

export default {
  name: 'Restaurants',
  mounted(){
    this.loadRestaurants();
  },
  methods: {
    getProductImage(imageField){
      if(imageField){
        const images = JSON.parse(imageField);
        const image = images[0];
        return image;
      }
    },
    async loadBrandName(id){
      try{
        const loginInfo = Common.getLoginInfo();
        const brand = await Brand.loadBrand(this.apiUrl, id, loginInfo);
        const brandName = brand.name;
        return brandName;
      }catch(err){
        console.log(err);
      }
    },
    async loadRestaurants(){
      try{
        const loginInfo = Common.getLoginInfo();
        const restaurants = await Restaurant.loadAllRestaurants(this.apiUrl, loginInfo);
        const fullRestaurantsInfo = [];
        for(let i = 0; i < restaurants.length; i++){
          const restaurant = restaurants[i];
          const brand = await Brand.loadBrand(this.apiUrl, restaurant.related_brand, loginInfo);
          const brandName = brand.name;
          restaurants[i].related_brand = brandName;
          fullRestaurantsInfo.push(restaurant);
        }
        this.restaurants = fullRestaurantsInfo;
      }catch(err){
        console.log(err);
      }
    },
  },
  data(){
    return {
      restaurants: [],
    }
  },
  computed: mapState({
    langcode: state => state.langcode,
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
.categories-filter{
  padding-left: 20px;
  font-weight: bold;
  margin-bottom: 50px;
  display: flex;
  .filter-label{
    margin-right: 20px;
  }
}
.product-image-wrapper{
  width: 200px;
  height: 200px;
  position: relative;
  overflow: hidden;
  .product-image{
    overflow: hidden;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.product-image-td{
  width: 20%;
}
</style>
